import { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import RankWinrateChart from '../components/charts/RankWinrateChart';
import RankMatchesChart from '../components/charts/RankMatchesChart';
import WinrateChart from '../components/charts/WinrateChart';
import MVPChart from '../components/charts/MVPChart';
import { getCDNVersion, getCDNData, getSummonerData, getMoreMatches } from '../API';
import Header from '../components/Header/Header';
import Spinner from '../components/Spinner/Spinner';
import MatchCard from '../components/MatchCard/MatchCard';
import {
	useLocalStorageState,
	BASE_CDN_URL,
	queueTypes,
	getDivision,
	rankImages,
	calculateWinrate,
	CHAMP_IMG_URL,
	formatNumberWithThousands,
	BASE_RANK_URL
} from '../Utils';
import '../styles/SummonerPage.css';

const pageSize = 10;
const defaultRank = [
	{ leagueId: 1, rank: 'I', tier: 'UNRANKED', queueType: 'RANKED_SOLO_5x5' },
	{ leagueId: 2, rank: 'I', tier: 'UNRANKED', queueType: 'RANKED_FLEX_SR' }
];
const MASTERY_LEVEL_URL = BASE_RANK_URL + 'mastery-level-plate.png';
const cacheTime = 2 * 60 * 1000; // 2 minutes

const SummonerPage = () => {
	const location = useLocation();
	const { region, summonerName } = useParams();
	const cacheKey = summonerName + '-' + region;
	const [cachedData, setCachedData] = useLocalStorageState(cacheKey, {});

	const [BASE_IMG_URL, setBASE_IMG_URL] = useState('');
	const [BASE_CHAMP_URL, setBASE_CHAMP_URL] = useState('');
	const [BASE_SUMMONER_URL, setBASE_SUMMONER_URL] = useState('');
	const [BASE_RUNE_URL, setBASE_RUNE_URL] = useState('');

	const [champData, setChampData] = useState({});
	const [spellData, setSpellData] = useState({});
	const [runeData, setRuneData] = useState({});

	const [profileData, setProfileData] = useState({});
	const [rankData, setRankData] = useState([]);
	const [masteryData, setMasteryData] = useState([]);
	const [matchesData, setMatchesData] = useState([]);
	const [profileIconUrl, setProfileIconUrl] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState('');

	const [sigmas, setSigmas] = useState(0);
	const [alphas, setAlphas] = useState(0);

	const [matchesStartPage, setMatchesStartPage] = useState(0);
	const [isLoadingMore, setIsLoadingMore] = useState(false);
	const [showPaginationButton, setShowPaginationButton] = useState(true);

	useEffect(() => {
		(async () => {
			const versionData = await getCDNVersion();

			setBASE_IMG_URL(BASE_CDN_URL + versionData[0] + '/img/');
			setBASE_CHAMP_URL(BASE_CDN_URL + versionData[0] + '/data/en_US/champion.json');
			setBASE_SUMMONER_URL(BASE_CDN_URL + versionData[0] + '/data/en_US/summoner.json');
			setBASE_RUNE_URL(BASE_CDN_URL + versionData[0] + '/data/en_US/runesReforged.json');
		})();
	}, []);

	useEffect(() => {
		if (BASE_CHAMP_URL && BASE_RUNE_URL && BASE_SUMMONER_URL) {
			(async () => {
				try {
					const [championsData, summonerData, runes] = await Promise.all([
						getCDNData(BASE_CHAMP_URL),
						getCDNData(BASE_SUMMONER_URL),
						getCDNData(BASE_RUNE_URL)
					]);

					setChampData(championsData?.data);
					setSpellData(summonerData?.data);
					setRuneData(runes);
				} catch (error) {
					console.error('Error fetching data:', error);
				}
			})();
		}
	}, [BASE_CHAMP_URL, BASE_RUNE_URL, BASE_SUMMONER_URL]);

	useEffect(() => {
		setSigmas(0);
		setAlphas(0);
	}, [location]);

	useEffect(() => {
		if (BASE_IMG_URL && profileData) {
			const iconUrl = BASE_IMG_URL + 'profileicon/' + profileData.profileIconId + '.png';
			setProfileIconUrl(iconUrl);
		}
	}, [BASE_IMG_URL, profileData]);

	useEffect(() => {
		(async () => {
			if (region && summonerName) {
				setIsLoading(true);
				const now = Date.now();
				if (cachedData[cacheKey] && now - cachedData[cacheKey].timestamp < cacheTime) {
					const data = cachedData[cacheKey].data;
					setProfileData(data.profile);
					setRankData(data.rank.filter((rank) => Object.keys(queueTypes).includes(rank.queueType)));
					setMasteryData(data.mastery);
					setMatchesData(data.matches);
					setMatchesStartPage(data.matchesStartPage);
				} else {
					try {
						const response = await getSummonerData(region, summonerName);
						if (response.error) {
							setError(response.error);
						} else {
							setProfileData(response.profile);
							setRankData(
								response.rank.filter((rank) => Object.keys(queueTypes).includes(rank.queueType))
							);
							setMasteryData(response.mastery);
							setMatchesData(response.matches);
							setMatchesStartPage(response.matchesStartPage);
							setCachedData({
								[cacheKey]: {
									timestamp: Date.now(),
									data: response
								}
							});
						}
					} catch (error) {
						console.error('Error fetching data:', error);
					}
				}
				setIsLoading(false);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [region, summonerName]);

	const updateSigma = useCallback((index) => {
		if (index < pageSize) {
			setSigmas((prevSigmas) => prevSigmas + 1);
		}
	}, []);

	const updateAlpha = useCallback((index) => {
		if (index < pageSize) {
			setAlphas((prevAlphas) => prevAlphas + 1);
		}
	}, []);

	const handleShowMore = async () => {
		setIsLoadingMore(true);

		const response = await getMoreMatches(profileData?.puuid, matchesStartPage);

		setIsLoadingMore(false);
		setShowPaginationButton(response?.matches?.length >= pageSize);
		setMatchesStartPage(response?.matchesStartPage);
		setMatchesData([...matchesData, ...response?.matches]);
	};

	const getChamp = (champId) => {
		const id = champId + '';
		for (const [key, val] of Object.entries(champData)) {
			if (val?.key === id) {
				return key;
			}
		}
		return 'Draven';
	};

	const getMasteryChampBanner = (mastery) => {
		const lvl = mastery?.championLevel > 10 ? 10 : mastery?.championLevel;
		const bannerURL =
			BASE_RANK_URL +
			'mastery-banner-' +
			(lvl === 10 ? '3.svg' : lvl < 10 && lvl >= 5 ? '2.svg' : lvl < 5 && lvl > 0 ? '1.svg' : '0.png');
		const masteryURL = BASE_RANK_URL + 'mastery-' + lvl + '.png';

		return (
			<div className="masteryProfile">
				<img
					src={`${CHAMP_IMG_URL + getChamp(mastery?.championId) + '.png'}`}
					alt="Champion"
					className="mainChamp"
				/>
				<div className="masteryBanner">
					<img src={bannerURL} alt="Mastery Banner" className="bannerLevel" />
					<img src={masteryURL} alt="Mastery Level" className="masteryLevel" />
				</div>
				<div className="masteryPlate">
					<img src={MASTERY_LEVEL_URL} alt="Mastery Banner" className="masteryLevelPlate" />
					<span className="champMasteryLevel">{mastery?.championLevel}</span>
				</div>
			</div>
		);
	};

	return (
		<div className="page">
			<Header />
			<div className="summonerPage">
				{error ? (
					<div className="error">
						{error} {':('}
					</div>
				) : isLoading ? (
					<div className="loading">
						<Spinner />
						Fetching sigma stats...
					</div>
				) : (
					<>
						<div>
							<div className="summonerProfile card">
								{profileIconUrl && (
									<div className="summonerIcon">
										<img className="profileIcon" src={profileIconUrl} alt="Profile Icon" />
										<span className="level">{profileData?.summonerLevel}</span>
									</div>
								)}
								<div className="summonerName">
									<span className="profileName">
										<span>{profileData?.gameName}</span>
										<span className="tag">#{profileData?.tagLine}</span>
									</span>
								</div>
							</div>
							<div className="summonerRank">
								{rankData?.length > 1 ? (
									rankData.map((rank) => (
										<div key={rank?.leagueId} className="rank card">
											<div className="queue">
												<span className="separator">|</span>
												{queueTypes[rank?.queueType]}
											</div>
											<div className="division">
												<img src={rankImages[rank?.tier]} alt="rank" />
												<div>
													<div className="rankStats">
														<span className="rankDivision">
															{getDivision(rank?.tier, rank?.rank)}
														</span>
														<span>
															{rank?.wins}W {rank?.losses}L
														</span>
													</div>
													<div className="rankStats">
														<span>{rank?.leaguePoints} LP</span>
														<span>
															{calculateWinrate(rank?.wins, rank?.losses)}% Win Rate
														</span>
													</div>
												</div>
											</div>
										</div>
									))
								) : rankData?.length === 1 ? (
									<>
										<div key={rankData[0]?.leagueId} className="rank card">
											<div className="queue">
												<span className="separator">|</span>
												{queueTypes[rankData[0]?.queueType]}
											</div>
											<div className="division">
												<img src={rankImages[rankData[0]?.tier]} alt="rank" />
												<div>
													<div className="rankStats">
														<span className="rankDivision">
															{getDivision(rankData[0]?.tier, rankData[0]?.rank)}
														</span>
														<span>
															{rankData[0]?.wins}W {rankData[0]?.losses}L
														</span>
													</div>
													<div className="rankStats">
														<span>{rankData[0]?.leaguePoints} LP</span>
														<span>
															{calculateWinrate(rankData[0]?.wins, rankData[0]?.losses)}%
															Win Rate
														</span>
													</div>
												</div>
											</div>
										</div>
										{rankData[0]?.queueType === 'RANKED_SOLO_5x5' ? (
											<div key={defaultRank[1]?.leagueId} className="rank card">
												<div className="queue">
													<span className="separator">|</span>
													{queueTypes[defaultRank[1]?.queueType]}
												</div>
												<div className="division">
													<img src={rankImages[defaultRank[1]?.tier]} alt="rank" />
													<div>
														<div className="rankStats">
															<span className="rankDivision">
																{getDivision(
																	defaultRank[1]?.tier,
																	defaultRank[1]?.rank
																)}
															</span>
														</div>
													</div>
												</div>
											</div>
										) : (
											<div key={defaultRank[0]?.leagueId} className="rank card">
												<div className="queue">
													<span className="separator">|</span>
													{queueTypes[defaultRank[0]?.queueType]}
												</div>
												<div className="division">
													<img src={rankImages[defaultRank[0]?.tier]} alt="rank" />
													<div>
														<div className="rankStats">
															<span className="rankDivision">
																{getDivision(
																	defaultRank[0]?.tier,
																	defaultRank[0]?.rank
																)}
															</span>
														</div>
													</div>
												</div>
											</div>
										)}
									</>
								) : (
									defaultRank.map((rank) => (
										<div key={rank?.leagueId} className="rank card">
											<div className="queue">
												<span className="separator">|</span>
												{queueTypes[rank?.queueType]}
											</div>
											<div className="division">
												<img src={rankImages[rank?.tier]} alt="rank" />
												<div>
													<div className="rankStats">
														<span className="rankDivision">
															{getDivision(rank?.tier, rank?.rank)}
														</span>
													</div>
												</div>
											</div>
										</div>
									))
								)}
							</div>
							<div className="chartsContainer">
								<div className="card">
									<div className="queue">
										<span className="separator">|</span>
										Ranked Stats
									</div>
									<div className="charts">
										<RankWinrateChart rankData={rankData} />
										<RankMatchesChart rankData={rankData} />
									</div>
								</div>
								<div className="card">
									<div className="queue">
										<span className="separator">|</span>
										Performance (over the last {pageSize} games)
									</div>
									<div className="charts">
										<WinrateChart
											matchesData={matchesData}
											puuid={profileData?.puuid}
											pageSize={pageSize}
										/>
										<MVPChart sigmas={sigmas} alphas={alphas} />
									</div>
								</div>
							</div>
							<div className="masteryContainer card">
								<div className="queue">
									<span className="separator">|</span>
									Top 3 Champion Mastery
								</div>
								<div className="topMastery">
									{masteryData?.map((mastery) => (
										<div key={mastery?.championId} className="champMastery">
											{getMasteryChampBanner(mastery)}
											<span className="champName">{getChamp(mastery?.championId)}</span>
											<span className="masteryPoints">
												{formatNumberWithThousands(mastery?.championPoints)} Points
											</span>
										</div>
									))}
								</div>
							</div>
						</div>
						<div className="matchHistory">
							<div>
								<span className="separator">|</span>
								Match History
							</div>
							{matchesData?.map((match, index) => (
								<div key={match?.gameName}>
									<MatchCard
										index={index}
										match={match}
										puuid={profileData?.puuid}
										region={region}
										rankData={rankData}
										champData={champData}
										spellData={spellData}
										runeData={runeData}
										BASE_IMG_URL={BASE_IMG_URL}
										updateSigma={updateSigma}
										updateAlpha={updateAlpha}
									/>
								</div>
							))}
							<div className="loadMore">
								{isLoadingMore ? (
									<Spinner />
								) : (
									showPaginationButton && (
										<button className="showMoreBtn" onClick={handleShowMore}>
											Show More
										</button>
									)
								)}
							</div>
						</div>
						<div className="googleAds"></div>
					</>
				)}
			</div>
		</div>
	);
};

export default SummonerPage;
